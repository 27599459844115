(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('canceropoleGsoApp')
        .constant('VERSION', "1.2.16")
        .constant('DEBUG_INFO_ENABLED', false)
        .constant('BUILD_DATE', "2024-9-25 08:56:24")
;
})();
